var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BRow,{staticClass:"progress-report"},[(!_setup.isAStudent)?_c(_setup.BCol,{attrs:{"md":"3"}},[_c(_setup.FilterView,{ref:"filterView",attrs:{"form":_setup.filterForm}})],1):_vm._e(),_c(_setup.BCol,{attrs:{"md":_setup.isAStudent ? 12 : 9}},[(_setup.isAStudent)?_c(_setup.StudentSimpleFilter,{attrs:{"form":_setup.filterForm},on:{"filter-data":_setup.handleFilterForStudents}}):_c(_setup.DateRangeFilter,{ref:"dateRangeFilter",attrs:{"form":_setup.dateFilter,"is-comparative-view":_setup.isComparative,"is-processing":_setup.isProcessing,"hide-export":_setup.hideExportInDatePicker},on:{"exportData":_setup.handleExport,"applyFilter":_setup.handleApplyFilter}}),(_setup.filterForm.classroom)?_c('div',{staticClass:"row mt-2"},_vm._l((_setup.reportCounts),function(i,index){return _c('div',{key:i,class:_setup.reportCounts.length == 1 ? 'col-12' : 'col-6'},[(_setup.filterForm.reportType === 'general')?[(_setup.filterForm.metric === 'lesson_completion')?_c(_setup.LessonCompleteReport,{ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
              startDate: _setup.dateFilter[`startDate${index + 1}`],
              endDate: _setup.dateFilter[`endDate${index + 1}`]
            },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):(_setup.filterForm.metric === 'score_percentage')?_c(_setup.ScorePercentage,{ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
              startDate: _setup.dateFilter[`startDate${index + 1}`],
              endDate: _setup.dateFilter[`endDate${index + 1}`]
            },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):(_setup.filterForm.metric === 'language_skill')?_c(_setup.LanguageSkill,{ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
              startDate: _setup.dateFilter[`startDate${index + 1}`],
              endDate: _setup.dateFilter[`endDate${index + 1}`]
            },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):(_setup.filterForm.metric === 'daily_use_age')?_c(_setup.DailyUseAgeReport,{ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"filter-counter":_setup.applyFilterCounter,"dates":{
              startDate: _setup.dateFilter[`startDate${index + 1}`],
              endDate: _setup.dateFilter[`endDate${index + 1}`]
            }}}):_vm._e()]:_vm._e(),(_setup.filterForm.reportType === 'average')?_c(_setup.AverageReport,{key:_setup.filterForm.metric + _setup.filterForm.reportType,ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
            startDate: _setup.dateFilter[`startDate${index + 1}`],
            endDate: _setup.dateFilter[`endDate${index + 1}`]
          },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):_vm._e(),(_setup.filterForm.reportType === 'growth')?_c(_setup.GrowthReport,{key:_setup.filterForm.metric + _setup.filterForm.reportType,ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
            startDate: _setup.dateFilter[`startDate${index + 1}`],
            endDate: _setup.dateFilter[`endDate${index + 1}`]
          },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):_vm._e()],2)}),0):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }