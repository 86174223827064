<template>
  <BOverlay :show="isProcessing">
    <b-card v-if="isApiCalled"
            body-class="p-0 m-0"
    >
      <div v-if="form.graphType === 'table'"
           :set="dataItem = getReportData().data"
      >
        <div v-if="dataItem.length === 0"
             class="p-2"
        >
          <h4>
            No Data Found
          </h4>
        </div>
        <b-table v-else
                 :items="getReportData().data"
        />
      </div>
      <vue-apex-charts
        v-else
        :height="chartHeight"
        type="bar"
        :options="options"
        :series="series"
      />
    </b-card>
  </BOverlay>
</template>

<script setup>
import {
  watch,
  getCurrentInstance,
  computed,
  ref,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import { BOverlay, BTable, BCard } from 'bootstrap-vue'
import { LessonCompletingCalc } from '@/views/v2/common/class/GroupReport/helper/reportHelper'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  dates: {
    type: Object,
    required: true,
  },
  filterCounter: {
    type: Number,
    default: 0,
  },
})

const root = getCurrentInstance().proxy.$root
const metricType = props.form.metric

// Constants for chart height calculation
const BASE_CHART_HEIGHT = 100 // Base height
const CATEGORY_HEIGHT = 50 // Height per category
const MAX_CHART_HEIGHT = 800 // Maximum chart height
const MIN_CHART_HEIGHT = 100 // Minimum chart height

const emits = defineEmits(['updateProcessing'])

// Refs and computed properties
const isProcessing = ref(false)
const scores = ref([])
const categoriesData = ref([])
const isApiCalled = ref(null)
const barColors = ref([])

// Dynamic chart height calculation
const chartHeight = computed(() => {
  // Calculate height based on number of categories
  const calculatedHeight = BASE_CHART_HEIGHT
    + (categoriesData.value.length * CATEGORY_HEIGHT)

  // Ensure height is within min and max bounds
  return Math.min(
    Math.max(calculatedHeight, MIN_CHART_HEIGHT),
    MAX_CHART_HEIGHT,
  )
})

// Color assignment based on score
const getColorBasedOnScore = score => {
  if (score >= 90) return '#00b050' // Dark green for very high scores (90-100)
  if (score >= 80) return '#70AD47' // Green for high scores (80-89)
  if (score >= 70) return '#92d050' // Light green for good scores (70-79)
  if (score >= 60) return '#c6e0b4' // Pale green for above average scores (60-69)
  if (score >= 50) return '#ffff00' // Yellow for average scores (50-59)
  if (score >= 40) return '#ffda00' // Dark yellow for below average scores (40-49)
  if (score >= 30) return '#ffc000' // Orange for low scores (30-39)
  if (score >= 20) return '#ff8000' // Dark orange for very low scores (20-29)
  return '#ff0000' // Red for poor scores (0-19)
}

// Update bar colors based on scores
const updateBarColors = () => {
  barColors.value = scores.value.map(score => getColorBasedOnScore(score))
}

// Calculate average score
const calculateAverageScore = () => {
  if (scores.value.length === 0) return 0
  const avg = scores.value.reduce((a, b) => a + b, 0) / scores.value.length
  return Math.round(avg)
}

// Series for the chart
const series = computed(() => [
  {
    name: metricType.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    data: scores.value,
  },
])

// Chart options
const options = computed(() => ({
  chart: {
    type: 'bar',
    height: chartHeight.value,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: categoriesData.value.length > 10 ? '60%' : '80%',
      distributed: true,
      dataLabels: {
        position: 'top',
      },
    },
  },
  colors: barColors.value,
  dataLabels: {
    enabled: true,
    formatter(val) {
      return `${val}%`
    },
    offsetX: 50,
    style: {
      fontSize: '12px',
      colors: ['#000'],
    },
  },
  stroke: {
    width: 1,
    colors: ['#fff'],
  },
  title: {
    text: metricType.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    align: 'center',
    style: {
      fontSize: '18px',
    },
  },
  xaxis: {
    categories: categoriesData.value,
    labels: {
      style: {
        fontSize: '14px',
      },
    },
    max: 100,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },
    },
  },
  annotations: {
    xaxis: [{
      x: calculateAverageScore(), // Dynamic average calculation
      borderColor: '#FF6B00',
      strokeDashArray: 0,
      strokeWidth: 4,
      label: {
        borderColor: '#FF6B00',
        style: {
          color: '#fff',
          background: '#FF6B00',
        },
        text: 'AVERAGE',
        position: 'bottom',
      },
    }],
  },
  tooltip: {
    y: {
      formatter(val) {
        return `${val}%`
      },
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  legend: {
    show: false,
  },
}))

// Fetch report data
const fetchReportData = () => {
  isProcessing.value = true
  isApiCalled.value = true
  emits('updateProcessing', true)

  useJwt.getProgressReport(props.form.classroom, metricType, {
    params: {
      start_date: props.dates.startDate,
      end_date: props.dates.endDate,
      student_ids: props.form.students,
      tags: props.form.tags,
    },
  }).then(res => {
    if (metricType === 'lesson_completion') {
      const lessonDoneReport = res.data.data.lessonDone.score
      const expectedLessonDone = res.data.data.lessonDone.expected
      const { categories, totalScore } = LessonCompletingCalc({ lessonDoneReport, expectedLessonDone }, root)
      scores.value = totalScore
      categoriesData.value = categories
    } else {
      scores.value = res.data.data.map(i => +i.score_percentage)
      categoriesData.value = res.data.data.map(i => `${i.firstname} ${i.lastname}`)
    }

    updateBarColors() // Update colors based on new scores
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
    emits('updateProcessing', false)
  })
}

// Watch for filter changes
watch(() => props.filterCounter, () => {
  fetchReportData()
}, { immediate: true })

// Export data function
const getReportData = () => {
  const requiredData = []
  categoriesData.value.forEach((v, index) => {
    requiredData.push({
      student: v,
      score: scores.value[index],
    })
  })
  return {
    fileName: 'lesson_completion',
    data: requiredData,
  }
}
</script>
