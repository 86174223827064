<template>
  <BOverlay :show="isProcessing">
    <b-card v-if="isApiCalled"
            body-class="p-0 m-0"
    >
      <div v-if="form.graphType === 'table'"
           :set="dataItem = getReportData().data"
      >
        <div v-if="dataItem.length === 0"
             class="p-2"
        >
          <h4>
            No Data Found
          </h4>
        </div>
        <b-table v-else
                 :items="getReportData().data"
        />
      </div>
      <vue-apex-charts
        v-else
        height="500"
        :type="form.graphType"
        :options="options"
        :series="series"
      />

    </b-card>
  </BOverlay>
</template>
<script setup>
// import useJwt from '@/auth/jwt/useJwt'
import {
  watch, getCurrentInstance, computed, ref,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import { BOverlay, BTable, BCard } from 'bootstrap-vue'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  dates: {
    type: Object,
    required: true,
  },
  filterCounter: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits(['updateProcessing'])
const isProcessing = ref(false)
const scores = ref([])
const categoriesData = ref([])
const isApiCalled = ref(false)

const series = computed(() => scores.value)

const options = computed(() => ({
  chart: {
    id: 'vuechart-example',
    stacked: true,
    type: 'bar',
  },
  plotOptions: {
    bar: {
      columnWidth: categoriesData.value.length <= 5 ? '10%' : '70%',
    },
  },
  xaxis: {
    categories: categoriesData.value,
  },
  dataLabels: {
    formatter(val) {
      return `${val}%`
    },
  },
}))

const root = getCurrentInstance().proxy.$root

const fetchReportData = () => {
  isProcessing.value = true
  isApiCalled.value = true
  emits('updateProcessing', true)
  useJwt.getProgressReport(props.form.classroom, props.form.metric, {
    params: {
      start_date: props.dates.startDate,
      end_date: props.dates.endDate,
      student_ids: props.form.students,
      tags: props.form.tags,
    },
  }).then(res => {
    const requiredData = []
    const { data } = res.data
    const requiredSkill = ['reading', 'writing', 'speaking', 'listening']
    requiredSkill.forEach(item => {
      requiredData.push({
        name: item,
        data: data.map(i => i.skill_type.find(j => j.skill_type === item)?.cefr_rate || 0),
      })
    })
    const categories = data.map(i => i.student_name)
    scores.value = requiredData
    categoriesData.value = categories
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
    emits('updateProcessing', false)
  })
}

watch(() => props.filterCounter, () => {
  fetchReportData()
}, { immediate: true })

// ! the function is responsible to export the data
// eslint-disable-next-line no-unused-vars
const getReportData = () => {
  const requiredData = []
  const reading = scores.value.find(i => i.name === 'reading')?.data
  const writing = scores.value.find(i => i.name === 'writing')?.data
  const speaking = scores.value.find(i => i.name === 'speaking')?.data
  const listening = scores.value.find(i => i.name === 'listening')?.data

  categoriesData.value.forEach((v, index) => {
    requiredData.push({
      student: v,
      reading: reading[index],
      writing: writing[index],
      speaking: speaking[index],
      listening: listening[index],
    })
  })
  return {
    fileName: 'lesson_completion',
    data: requiredData,
  }
}
</script>
