<template>
  <div>
    <!-- Add filter buttons at the top -->
    <div class="mb-3 d-flex justify-content-end">
      <b-button-group>
        <b-button
          :variant="timeFilter === 'weekly' ? 'primary' : 'outline-primary'"
          @click="setTimeFilter('weekly')"
        >
          Weekly
        </b-button>
        <b-button
          :variant="timeFilter === 'monthly' ? 'primary' : 'outline-primary'"
          @click="setTimeFilter('monthly')"
        >
          Monthly
        </b-button>
      </b-button-group>
    </div>

    <BOverlay :show="isProcessing">
      <b-card v-if="isApiCalled"
              body-class="p-0 m-0"
      >
        <div v-if="form.graphType === 'table'"
             :set="dataItem = getReportData().data"
        >
          <div v-if="dataItem.length === 0"
               class="p-2"
          >
            <h4>
              No Data Found
            </h4>
          </div>
          <b-table v-else
                   :items="getReportData().data"
          />
        </div>
        <div v-else>
          <TimeLineView v-if="form.graphType === 'bar'"
                        :data="chartData"
                        :timeline="categories"
                        :time-frame="timeFilter"
                        :selected-students="selectedStudents"
          />
          <div v-else>
            <vue-apex-charts
              height="500"
              type="line"
              :options="options"
              :series="series"
            />
          </div>
        </div>
      </b-card>

      <div v-if="chartData.length > 0"
           class="mt-3 text-muted"
      >
        <small>
          <i>Note: If the selected student is not among the top performers, their data will be merged with other top students in the chart view.</i>
        </small>
      </div>
    </BOverlay>
  </div>
</template>
<script setup>
// import useJwt from '@/auth/jwt/useJwt'
import {
  watch, getCurrentInstance, computed, ref, onMounted,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import {
  BOverlay, BTable, BCard, BButton, BButtonGroup,
} from 'bootstrap-vue'
import TimeLineView from '../components/TimeLineView.vue'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  dates: {
    type: Object,
    required: true,
  },
  filterCounter: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits(['updateProcessing'])
const isProcessing = ref(false)
const chartData = ref([])
const isApiCalled = ref(null)
const timeFilter = ref('monthly') // Default to weekly view

// Define the categories for X-axis - adjust these to match what the values represent
const categories = ref(['Category 1', 'Category 2', 'Category 3', 'Category 4'])

const series = computed(() => {
  if (!chartData.value.length) return []

  return chartData.value.map(item => ({
    name: item.name,
    data: item.data.map(val => parseFloat(val.toFixed(2))), // Round to 2 decimal places
  }))
})

const options = computed(() => ({
  chart: {
    type: 'line',
    height: 500,
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'straight',
    width: 3,
  },
  colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A'],
  xaxis: {
    categories: categories.value,
  },
  tooltip: {
    y: {
      formatter(value) {
        return value.toFixed(2)
      },
    },
  },
  legend: {
    position: 'top',
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
}))

const root = getCurrentInstance().proxy.$root
const metricType = props.form.metric
const selectedStudents = ref([])

const fetchReportData = () => {
  isProcessing.value = true
  isApiCalled.value = true
  emits('updateProcessing', true)
  useJwt.getProgressReport(props.form.classroom, metricType, {
    params: {
      start_date: props.dates.startDate,
      end_date: props.dates.endDate,
      student_ids: props.form.students,
      isComparative: 1,
      time_frame: timeFilter.value,
      tags: props.form.tags,
    },
  }).then(res => {
    if (metricType === 'attendance') {
      const { records, categories: graphCat } = res.data.data
      categories.value = graphCat
      categories.value = graphCat

      // Assuming records has the same structure as the JSON data you provided
      chartData.value = records
    } else {
      // const lessonDoneReport = res.data.data.lessonDone.score
      // const expectedLessonDone = res.data.data.lessonDone.expected
      const { records, categories: graphCat } = res.data.data
      categories.value = graphCat
      // Assuming records has the same structure as the JSON data you provided
      chartData.value = records
    }
    console.log(chartData)
    selectedStudents.value = res.data.data.selected_students.map(i => +i)
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
    emits('updateProcessing', false)
  })
}
// Function to set the time filter
const setTimeFilter = filter => {
  timeFilter.value = filter
  fetchReportData()
}

watch(() => props.filterCounter, () => {
  fetchReportData()
}, { immediate: true })

// For the table data export
const getReportData = () => {
  const requiredData = []

  if (chartData.value.length > 0) {
    // eslint-disable-next-line no-shadow
    chartData.value.forEach(series => {
      const studentData = {
        student: series.name,
        timeFrame: timeFilter.value.charAt(0).toUpperCase() + timeFilter.value.slice(1), // Add time frame info
      }

      // Add each data point as a separate column with rounded values
      series.data.forEach((value, index) => {
        studentData[`${categories.value[index] || `Category ${index + 1}`}`] = parseFloat(value.toFixed(2))
      })

      requiredData.push(studentData)
    })
  }

  return {
    fileName: `${metricType}_${timeFilter.value}_report`,
    data: requiredData,
  }
}

// Initialize data on component mount if needed
onMounted(() => {
  if (props.filterCounter === 0) {
    fetchReportData()
  }
})
</script>
