<template>
  <div class="timeline-container">
    <div class="timeline-table">
      <!-- Header Row -->
      <div class="timeline-header">
        <div class="timeline-cell name-header">
          NAME
        </div>
        <div v-for="(month, index) in timeline"
             :key="index"
             class="timeline-cell"
        >
          {{ formatMonth(month) }}
        </div>
      </div>

      <!-- Data Rows -->
      <div v-for="(person, idx) in userData"
           :key="person.id"
           class="timeline-row"
           :class="{ 'alternate-row': idx % 2 !== 0, 'student-highlight': selectedStudents.includes(person.id) }"
      >
        <!-- Name Cell -->
        <div class="timeline-cell name-cell">
          <div class="avatar"
               :style="{ backgroundColor: getAvatarColor(person.name) }"
          >
            {{ getInitials(person.name) }}
          </div>
          <div class="user-info">
            <div class="user-name">
              {{ person.name }}
            </div>
          </div>
        </div>

        <!-- Progress Cells -->
        <div v-for="(value, valueIdx) in person.data"
             :key="valueIdx"
             class="timeline-cell progress-cell"
        >
          <div class="progress-container">
            <div :id="`progress-${person.id}-${valueIdx}`"
                 v-b-tooltip.hover.top="Math.min(value.toFixed(2), 100) + '%'"
                 class="progress-bar"
                 :style="{ width: Math.min(value, 100) + '%',
                           backgroundColor: value > 0 ? '#4caf50' : '#e9e9e9' }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'TimelineComponent',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    timeline: {
      type: Array,
      required: true,
    },
    timeFrame: {
      type: String,
      default: 'monthly',
    },
    selectedStudents: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    userData() {
      return this.data
    },
  },
  methods: {
    formatMonth(timeValue) {
      if (this.timeFrame === 'weekly') {
        // Handle weekly format (e.g., "21-2024")
        const [week, year] = timeValue.split('-')
        return `Week ${week}, ${year}`
      }
      // Handle monthly format (e.g., "1-2024")
      const [month, year] = timeValue.split('-')
      const monthNames = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      }
      return `${monthNames[month]}, ${year}`
    },
    getAvatarColor(name) {
      // Generate consistent color based on name
      let hash = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < name.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = name.charCodeAt(i) + ((hash << 5) - hash)
      }
      const hue = hash % 360
      return `hsl(${hue}, 70%, 60%)`
    },
    getInitials(name) {
      return name.charAt(0)
    },
  },
}
</script>

  <style scoped>
  .timeline-container {
    overflow-x: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, sans-serif;
  }

  .timeline-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }

  .timeline-header {
    display: table-row;
    background-color: #3a57a6;
    color: white;
    font-weight: 500;
  }

  .timeline-row {
    display: table-row;
  }

  .timeline-cell {
    display: table-cell;
    padding: 12px;
    vertical-align: middle;
  }

  .name-header {
    width: 20%;
  }

  .name-cell {
    padding: 12px;
    display: flex;
    align-items: center;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .user-name {
    font-size: 14px;
    font-weight: 500;
  }

  .progress-cell {
    padding: 12px 16px;
  }

  .progress-container {
    height: 12px;
    background-color: #e9e9e9;
    border-radius: 4px;
    overflow: hidden;
  }

  .progress-bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 4px;
    transition: width 0.3s ease;
    cursor: pointer;
  }

  .alternate-row {
    background-color: #f8f9fa;
  }
  .student-highlight {
    background-color: rgba(0, 143, 251, 0.1);
    border-left: 3px solid #008FFB;
  }
  </style>
