<template>
  <div class="student-filter">
    <v-select
      v-model="props.form.classroom"
      :options="classrooms"
      label="text"
      :reduce="item => item.value"
      placeholder="Select Classroom"
      class="classroom-select"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
})

const classrooms = ref([])
const emits = defineEmits(['filter-data'])
const fetchClassrooms = async () => {
  try {
    const response = await useApollo.fetchClassRoomData('all', 1, 1000)
    classrooms.value = response.data.rooms.data.map(room => ({
      value: room.id,
      text: room.name,
    }))
    // If form.classroom is not set, select the first classroom
    if (!props.form.classroom && classrooms.value.length > 0) {
      props.form.classroom = classrooms.value[0].value
    }
    emits('filter-data')
  } catch (error) {
    console.error('Error fetching classrooms:', error)
  }
}

onMounted(() => {
  fetchClassrooms()
})
</script>

  <style lang="scss" scoped>
  .student-filter {
    margin: 1rem 0;
    display: flex;
    justify-content: flex-start;

    .classroom-select {
      width: 250px;

      :deep(.v-select) {
        .vs__dropdown-toggle {
          padding: 8px 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          transition: all 0.2s ease;

          &:hover {
            border-color: rgba(0, 0, 0, 0.2);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
          }
        }

        .vs__search {
          margin: 0;
          padding: 4px 0;
          font-size: 0.95rem;
        }

        .vs__selected {
          margin: 0 4px 0 0;
          padding: 0;
          font-weight: 500;
          color: #2c3e50;
        }

        .vs__open-indicator {
          fill: #6b7280;
        }

        .vs__clear {
          fill: #6b7280;
        }

        .vs__dropdown-menu {
          border-radius: 6px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        .vs__dropdown-option {
          padding: 10px 14px;

          &--highlight {
            background: rgba(0, 123, 255, 0.1);
            color: #0066cc;
          }
        }
      }
    }
  }
  </style>
