<template>
  <b-card
    class="form-card"
    header="Level Filter"
    body-class="p-0"
    header-class="bg-primary text-white text-center"
  >
    <validation-observer ref="filterView">
      <b-form class="mt-2 p-0"
              @submit.prevent="handleSubmit"
      >
        <validation-provider
          v-slot="{ errors }"
          name="class name"
          rules="required"
        >
          <!-- Classroom Dropdown -->
          <b-form-group label="Classroom"
                        label-for="classroom-select"
          >
            <v-select
              id="classroom-select"
              v-model="form.classroom"
              :options="classrooms"
              label="text"
              :reduce="i => i.value"
              placeholder="Select a classroom"
              @input="fetchStudentData"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!-- Student Multi-Select Dropdown -->
        <b-form-group v-if="AUTH_USER().usertype !== 'student'"
                      label="Students"
                      label-for="student-multiselect"
        >
          <v-select
            id="student-multiselect"
            v-model="form.students"
            :options="students"
            :multiple="true"
            label="text"
            placeholder="Select students"
            :reduce="i => i.value"
            :close-on-select="false"
            class="custom-multiselect"
          />
        </b-form-group>

        <!-- Report Type Dropdown -->
        <b-form-group label="Report Type"
                      label-for="report-type-select"
        >
          <b-form-select
            id="report-type-select"
            v-model="form.reportType"
            :options="reportTypes"
            placeholder="Select a report type"
            class="custom-select"
            @change="handleReportTypeChange"
          />
        </b-form-group>

        <!-- Metrics Dropdown - Conditional based on report type -->
        <b-form-group
          v-if="form.reportType"
          label="Metric"
          label-for="metric-select"
        >
          <b-form-select
            id="metric-select"
            v-model="form.metric"
            :options="availableMetrics"
            placeholder="Select a metric"
            class="custom-select"
          />
        </b-form-group>
        <!-- Tags Multi-Select Dropdown -->
        <b-form-group
          v-if="canShowTags"
          label="Tags"
          label-for="tags-multiselect"
        >
          <v-select
            v-model="form.tags"
            :options="calendarEventFilterTags"
            :multiple="true"
            label="text"
            placeholder="Select tags"
            :reduce="i => i.value"
            :close-on-select="false"
            class="custom-multiselect"
          />
        </b-form-group>
        <!-- Graph Type Dropdown -->
        <b-form-group label="Graph Type"
                      label-for="graph-type-select"
        >
          <b-form-select
            id="graph-type-select"
            v-model="form.graphType"
            :options="graphTypes"
            placeholder="Select a graph type"
            class="custom-select"
          />
        </b-form-group>

        <!-- View Type Dropdown -->
        <b-form-group label="View Type"
                      label-for="view-type-select"
        >
          <b-form-select
            id="view-type-select"
            v-model="form.viewType"
            :options="viewTypes"
            placeholder="Select a view type"
            class="custom-select"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script setup>
import {
  ref, onMounted, computed, watch,
} from 'vue'
import {
  BCard, BForm, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { calendarEventFilterTags } from '@/const/tags'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
})

// Dropdown options
const classrooms = ref([])

const students = ref([])

const reportTypes = [
  { value: 'general', text: 'General' },
  { value: 'average', text: 'Average' },
  { value: 'growth', text: 'Growth' },
]

// Metrics for each report type
const generalMetrics = [
  { value: 'lesson_completion', text: 'Lesson Completion' },
  { value: 'score_percentage', text: 'Score Percentage' },
  { value: 'language_skill', text: 'Language Skill' },
  { value: 'daily_use_age', text: 'Daily usage' },
]

const averageMetrics = [
  { value: 'lesson_completion', text: 'Lesson Completion' },
  { value: 'score_percentage', text: 'Score Percentage' },
]

const growthMetrics = [
  { value: 'lesson_completion', text: 'Lesson Completion' },
  { value: 'attendance', text: 'Attendance' },
]

// Compute available metrics based on the selected report type
const availableMetrics = computed(() => {
  switch (props.form.reportType) {
    case 'general':
      return generalMetrics
    case 'average':
      return averageMetrics
    case 'growth':
      return growthMetrics
    default:
      return []
  }
})

const graphTypes = computed(() => {
  switch (props.form.reportType) {
    case 'growth': {
      return [
        { value: 'table', text: 'Table View' },
        { value: 'bar', text: 'Bar Graph' },
        { value: 'line', text: 'Line Graph' },
      ]
    }
    default: {
      return [
        { value: 'table', text: 'Table View' },
        { value: 'bar', text: 'Bar Graph' },
      ]
    }
  }
})

const canShowTags = computed(() => ['language_skill', 'score_percentage', 'lesson_completion'].includes(props.form.metric))

const viewTypes = [
  { value: 'solo', text: 'Solo View' },
  { value: 'comparative', text: 'Comparative View' },
]

// Set default metric when report type changes
const handleReportTypeChange = () => {
  // Reset the metric when report type changes
  if (props.form.reportType === 'general') {
    props.form.metric = 'lesson_completion'
  } else if (props.form.reportType === 'average') {
    props.form.metric = 'lesson_completion'
  } else if (props.form.reportType === 'growth') {
    props.form.metric = 'lesson_completion'
  }
}

// Watch for report type changes
watch(() => props.form.reportType, handleReportTypeChange)

const fetchClassroom = async () => {
  const response = await useApollo.fetchClassRoomData('all', 1, 1000)
  classrooms.value = response.data.rooms.data.map(i => ({ value: i.id, text: i.name }))
}

const fetchStudentData = async () => {
  props.form.students = []
  const response = await useJwt.getStudentsOfClass(props.form.classroom)
  const studentData = response.data.data
  students.value = studentData.map(i => ({
    value: i.id,
    text: i.fullname,
  }))
}

// Initialize metric if needed
if (!props.form.metric) {
  props.form.metric = 'lesson_completion'
}

onMounted(() => {
  fetchClassroom()
  // If report type is already set, make sure the metric is appropriate
  if (props.form.reportType) {
    handleReportTypeChange()
  }
})

// Form submission handler
const handleSubmit = () => {}
</script>

<style scoped>
/* Styling for the card */
.form-card {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: white;
}

/* Dropdown customizations */
.custom-select {
  border-radius: 6px;
  border: 1px solid #ced4da;
  height: 45px;
}

/* Submit button styles */
.submit-button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  border-radius: 6px;
  font-weight: bold;
}

/* Add some padding to labels */
b-form-group > label {
  font-weight: 600;
  font-size: 14px;
  color: #495057;
}
</style>
