<template>
  <b-row class="progress-report">
    <b-col v-if="!isAStudent"
           md="3"
    >
      <FilterView ref="filterView"
                  :form="filterForm"
      />
    </b-col>
    <b-col :md="isAStudent ? 12 : 9">
      <StudentSimpleFilter v-if="isAStudent"
                           :form="filterForm"
                           @filter-data="handleFilterForStudents"
      />
      <DateRangeFilter v-else
                       ref="dateRangeFilter"
                       :form="dateFilter"
                       :is-comparative-view="isComparative"
                       :is-processing="isProcessing"
                       :hide-export="hideExportInDatePicker"
                       @exportData="handleExport"
                       @applyFilter="handleApplyFilter"
      />
      <div v-if="filterForm.classroom"
           class="row mt-2"
      >
        <div v-for="i,index in reportCounts"
             :key="i"
             :class="reportCounts.length == 1 ? 'col-12' : 'col-6'"
        >
          <!-- General Report Type - Shows based on metric selected -->
          <template v-if="filterForm.reportType === 'general'">
            <LessonCompleteReport
              v-if="filterForm.metric === 'lesson_completion'"
              ref="reportView"
              :form="filterForm"
              :dates="{
                startDate: dateFilter[`startDate${index + 1}`],
                endDate: dateFilter[`endDate${index + 1}`]
              }"
              :filter-counter="applyFilterCounter"
              @updateProcessing="v => isProcessing = v"
            />
            <ScorePercentage
              v-else-if="filterForm.metric === 'score_percentage'"
              ref="reportView"
              :form="filterForm"
              :dates="{
                startDate: dateFilter[`startDate${index + 1}`],
                endDate: dateFilter[`endDate${index + 1}`]
              }"
              :filter-counter="applyFilterCounter"
              @updateProcessing="v => isProcessing = v"
            />
            <LanguageSkill
              v-else-if="filterForm.metric === 'language_skill'"
              ref="reportView"
              :form="filterForm"
              :dates="{
                startDate: dateFilter[`startDate${index + 1}`],
                endDate: dateFilter[`endDate${index + 1}`]
              }"
              :filter-counter="applyFilterCounter"
              @updateProcessing="v => isProcessing = v"
            />
            <DailyUseAgeReport
              v-else-if="filterForm.metric === 'daily_use_age'"
              ref="reportView"
              :form="filterForm"
              :filter-counter="applyFilterCounter"
              :dates="{
                startDate: dateFilter[`startDate${index + 1}`],
                endDate: dateFilter[`endDate${index + 1}`]
              }"
            />
          </template>

          <!-- Average Report Type -->
          <AverageReport
            v-if="filterForm.reportType === 'average'"
            ref="reportView"
            :key="filterForm.metric + filterForm.reportType"
            :form="filterForm"
            :dates="{
              startDate: dateFilter[`startDate${index + 1}`],
              endDate: dateFilter[`endDate${index + 1}`]
            }"
            :filter-counter="applyFilterCounter"
            @updateProcessing="v => isProcessing = v"
          />

          <!-- Growth Report Type -->
          <GrowthReport
            v-if="filterForm.reportType === 'growth'"
            ref="reportView"
            :key="filterForm.metric + filterForm.reportType"
            :form="filterForm"
            :dates="{
              startDate: dateFilter[`startDate${index + 1}`],
              endDate: dateFilter[`endDate${index + 1}`]
            }"
            :filter-counter="applyFilterCounter"
            @updateProcessing="v => isProcessing = v"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script setup>
import { BRow, BCol } from 'bootstrap-vue'
import {
  ref, reactive, computed, getCurrentInstance,
} from 'vue'
import exportFromJSON from 'export-from-json'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import FilterView from './components/FilterView.vue'
import DateRangeFilter from './components/DateRangeFilter.vue'
import LessonCompleteReport from './reports/LessonCompleteReport.vue'
import ScorePercentage from './reports/ScorePercentage.vue'
import LanguageSkill from './reports/LanguageSkill.vue'
import DailyUseAgeReport from './reports/DailyUseAgeReport.vue'
import AverageReport from './reports/AverageReport.vue'
import GrowthReport from './reports/GrowthReport.vue'
import StudentSimpleFilter from './components/StudentSimpleFilter.vue'

const root = getCurrentInstance().proxy.$root
const isAStudent = computed(() => {
  const userData = getUserData()
  return userData?.usertype === 'student'
})

const filterForm = reactive({
  classroom: null,
  students: [],
  tags: [],
  reportType: 'general',
  metric: 'lesson_completion', // New property to replace the individual report type selections
  graphType: 'bar',
  viewType: 'solo',
})

const dateFilter = reactive({
  startDate1: null,
  endDate1: null,
  startDate2: null,
  endDate2: null,
})

const applyFilterCounter = ref(1)
const reportView = ref(null)
const filterView = ref(null)
const dateRangeFilter = ref(null)
const isProcessing = ref(false)

const handleExport = () => {
  let components = reportView.value
  if (!Array.isArray(components)) {
    components = [reportView.value]
  }
  components.forEach(item => {
    // eslint-disable-next-line no-underscore-dangle
    const { data, fileName } = item._setupProxy.getReportData()
    const exportType = 'csv'
    exportFromJSON({ data, fileName, exportType })
  })
}

const isComparative = computed(() => filterForm.viewType === 'comparative')

const reportCounts = computed(() => {
  if (isComparative.value) {
    return [1, 2]
  }
  return [1]
})

const hideExportInDatePicker = computed(() => filterForm.reportType === 'proficiency')

const handleApplyFilter = () => {
  const form = filterView.value
  form.$refs.filterView.validate().then(success => {
    if (success) {
      applyFilterCounter.value += 1
    }
  })
}

const handleFilterForStudents = () => {
  isProcessing.value = true
  useJwt.getStudentProgressReport(filterForm.classroom, {
    params: {
      start_date: dateFilter.startDate1,
      end_date: dateFilter.endDate1,
      student_ids: filterForm.students,
      tags: filterForm.tags,
    },
  }).then(res => {
    const { data } = res.data
    const { payload } = data
    filterForm.metric = payload.metrics
    filterForm.reportType = payload.report_type
    // let components = reportView.value
    // if (Array.isArray(components)) {
    //   // eslint-disable-next-line prefer-destructuring
    //   components = reportView.value[0]
    // }
    // // eslint-disable-next-line no-underscore-dangle
    // components._setupProxy.fetchReportData()
    // console.log(components)
    // Handle response if needed
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}

</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
  .dark-layout {
    .progress-report {
      .form-card{
        background-color: $theme-dark-card-bg !important;
        background-color: $theme-dark-box-shadow;
      }
      .single-line-ui{
        background-color: $theme-dark-card-bg !important;
        background-color: $theme-dark-box-shadow !important;
      }
    }
  }
</style>
